/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
const $ = require('jquery');
global.$ = global.jQuery = $;
require('bootstrap');

// start the Stimulus application
import './bootstrap';

require("bootstrap-datepicker");

$.fn.datepicker.dates['es'] = {
  days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sabado"],
  daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
  months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
  monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
  today: "Hoy",
  clear: "Borrar",
  format: "dd/mm/yyyy",
  titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
  weekStart: 0
};

$.fn.datepicker.dates['pt-BR'] = {
  days: ["Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sábado"],
  daysShort: ["Dom","Seg","Ter","Qua","Qui","Sex","Sáb"],
  daysMin: ["Do","Se","Te","Qu","Qu","Se","Sa"],
  months: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
  monthsShort: ["Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago","Set","Out","Nov","Dez"],
  today: "Hoje",
  clear: "Limpar",
  format: "dd/mm/yyyy",
  titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
  weekStart: 0
};

$('.datepicker_es').datepicker({
  language: 'es',
  format: 'dd/mm/yyyy',
  todayBtn: "linked",
  todayHighlight: true,
  autoclose: true,
});

$('.datepicker_en').datepicker({
  language: 'en',
  format: 'mm/dd/yyyy',
  todayBtn: "linked",
  todayHighlight: true,
  autoclose: true,
});

$('.datepicker_pt').datepicker({
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
    todayBtn: "linked",
    todayHighlight: true,
    autoclose: true,
});

  
